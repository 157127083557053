import React from 'react'
import SectionTitle from './common/SectionTitle';

export const AboutUsSection = () => {
  return (
    <>
      <section id='aboutUsSection' className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                    subtitle='About Us'
                    title='Who We Are?'
                    description='Your Success is Our Priority'
                    centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <div className='section-heading text-center'>
                <p>
                  We are a Product development and services provider having the
                  ability to design strategies to modernize, expand, and build
                  value and work closely with our partner ecosystem to deliver
                  impactful journeys for our clients and create 360° value
                  across their digital transformational journey. We listen,
                  advise, design and deliver world-class, user-friendly IT
                  products for clients in an agile way. How? Through our project
                  experience and collective knowledge, we have established our
                  way of bringing value to our clients through...
                </p>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
              >
                <div
                  className='
                    process-icon
                    border border-light
                    bg-custom-light
                    bg-dark
                    text-white
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-folder-tree fa-2x'></i>
                </div>
                <h3 className='h5'>Strategic Analysis</h3>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
              >
                <div
                  className='
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    bg-dark
                    text-white
                    p-3
                  '
                >
                  <i className='fad fa-bezier-curve fa-2x'></i>
                </div>
                <h3 className='h5'>Planning & Design</h3>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
              >
                <div
                  className='
                    bg-dark
                    text-white
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-layer-group fa-2x'></i>
                </div>
                <h3 className='h5'>Solution Delivery</h3>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
              >
                <div
                  className='
                    bg-dark
                    text-white
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-truck fa-2x'></i>
                </div>
                <h3 className='h5'>Managing & Innovating</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
