import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './IntroSection.css'

export const IntroSection = () => {
  const scrollToContactUs = () => {
    let home = document.getElementById('contactUsSection')
    home.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <section className='pt-100'>
      <div className='container-fluid p-0 introSection__carouselContainer'>
        <Carousel interval={6000} loop={true} keyboard={false}>
          <Carousel.Item>
            <video autoPlay muted loop>
              <source src='assets/images/2.mp4' type='video/mp4' />
            </video>
            <Carousel.Caption>
              <h2 className='text-white animate-text-up-down'>
                Empowering your future through digital transformation
              </h2>
              <div className='caption-text animate-text-down-up'>
                We're Go Orbit, Experience the power of stunning and
                high-performing software with our full-service & product
                development company. From fintech products to custom solutions,
                we deliver innovation that drives success.
              </div>
              <div>
                <button
                  onClick={scrollToContactUs}
                  className='mt-3 btn btn-secondary'
                >
                  Let's Talk
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              alt='We are Digital Strategy Advisors'
              className='d-block w-100'
              src='assets/images/slider.png'
            />
            <Carousel.Caption>
              <h2 className='text-white animate-text-up-down'>
                We are Digital Strategy Advisors
              </h2>
              <div>
                <button
                  onClick={scrollToContactUs}
                  className='mt-3 btn btn-secondary'
                >
                  Let's Talk
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              alt=' We build innovative products that redefine possibilities'
              className='d-block w-100'
              src={'assets/images/1.jpg'}
            />
            <Carousel.Caption>
              <h2 className='text-white animate-text-up-down'>
                We build innovative products that redefine possibilities
              </h2>
              <div>
                <button
                  onClick={scrollToContactUs}
                  className='mt-3 btn btn-secondary'
                >
                  Let's Talk
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <video autoPlay muted loop>
              <source src='assets/images/4.mp4' type='video/mp4' />
            </video>
            <Carousel.Caption>
              <h2 className='text-white animate-text-up-down'>
                We are technology consultants and delivery partners
              </h2>
              <div>
                <button
                  onClick={scrollToContactUs}
                  className='mt-3 btn btn-secondary'
                >
                  Let's Talk
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  )
}
