import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { OffCanvasMenu } from './OffCanvasMenu'

const billOrbitUrl = process.env.REACT_APP_BILL_ORBIT_URL

export const HeaderSection = ({ navDark }) => {
  const [scroll, setScroll] = useState(0)
  const [headerTop, setHeaderTop] = useState(0)

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header')
    setHeaderTop(stickyheader.offsetTop)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setHeaderTop])

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  const smoothScroll = (ele) => {
    ele.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const ScrollToHome = () => {
    let home = document.getElementById('home')
    smoothScroll(home)
  }

  const ScrollToAboutUs = () => {
    let features = document.getElementById('aboutUsSection')
    smoothScroll(features)
  }
  const scrollToServices = () => {
    let pricing = document.getElementById('servicesSection')
    smoothScroll(pricing)
  }

  const scrollToCareersSection = () => {
    let testimonials = document.getElementById('careerSection')
    smoothScroll(testimonials)
  }

  const scrollToProductInquirySection = () => {
    let home = document.getElementById('productInquirySection')
    smoothScroll(home)
  }

  return (
    <>
      <header
        id='home'
        className={`main-header ${
          navDark ? 'position-absolute ' : ''
        } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? 'navbar-dark' : 'navbar-light'
          } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className='container d-flex align-items-center justify-content-lg-between position-relative'>
            <Link
              to='/'
              className='navbar-brand d-flex align-items-center mb-md-0 text-decoration-none bill-orbit-logo'
            >
              <img
                src='assets/images/logo-white.png'
                alt='logo'
                className='img-fluid logo-white'
              />
              <img
                src='assets/images/logo.png'
                alt='logo'
                className='img-fluid logo-color'
              />
            </Link>
            <Link
              className='navbar-toggler position-absolute right-0 border-0'
              to='#offcanvasWithBackdrop'
              role='button'
            >
              <span
                className='far fa-bars'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasWithBackdrop'
                aria-controls='offcanvasWithBackdrop'
              ></span>
            </Link>

            <div className='collapse navbar-collapse justify-content-center'>
              <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
                <li>
                  <Link to='/' className='nav-link' onClick={ScrollToHome}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link' onClick={ScrollToAboutUs}>
                    About Us
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <Link
                    className='nav-link dropdown-toggle'
                    to='/'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Products
                  </Link>
                  <div className='dropdown-menu border-0 rounded-custom shadow py-0 bg-white'>
                    <div className='dropdown-grid rounded-custom width-half'>
                      <div className='dropdown-grid-item'>
                        <div className='dropdown-link'>
                          <div className='dropdown-info'>
                            <div className='drop-title'>
                              <a href={billOrbitUrl}>Bill Orbit</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to='/' className='nav-link' onClick={scrollToServices}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to='/'
                    className='nav-link'
                    onClick={scrollToProductInquirySection}
                  >
                    Product Inquiry
                  </Link>
                </li>
                <li>
                  <Link
                    to='/'
                    className='nav-link'
                    onClick={scrollToCareersSection}
                  >
                    Career
                  </Link>
                </li>
              </ul>
            </div>
            <OffCanvasMenu billOrbitUrl={billOrbitUrl} />
          </div>
        </nav>
      </header>
    </>
  )
}
