import React from 'react'
import Routers from './routers'

// Swiper CSS
import 'swiper/css'
import 'swiper/css/navigation'
import './App.css'

//bootstrap
import 'bootstrap/dist/js/bootstrap.bundle'

function App() {
  return (
    <>
      <Routers />
    </>
  )
}

export default App
