import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from './common/SectionTitle';

const billOrbitUrl = process.env.REACT_APP_BILL_ORBIT_URL

const ProductSection = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              {cardDark ? (
                <SectionTitle
                  subtitle='Flagship Product'
                  title='BillOrbit'
                  description='Worried about Track, Manage and Collect School Fees'
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  subtitle='Flagship Product'
                  title='BillOrbit'
                  description='Worried about Track, Manage and Collect School Fees'
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`shadow-sm highlight-card rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-analytics icon-sm text-primary'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>School Fee Collection and Payments</h3>
                    <p>
                    Bill Orbit automates your school fee billing with multiple payment options and 
                    charges your students after every billing cycle for you.
                    </p>
                    <h6 className='mt-4'>Included with...</h6>
                    <ul className='list-unstyled mb-0'>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Auto-bill your Students
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Prorate and bill automatically
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Secure Multiple Payment Options
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Payments Reminder and Notification
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        Personalize invoices
                      </li>
                    </ul>
                  </div>
                  <Link
                    to={billOrbitUrl}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-file-chart-line icon-sm text-success'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Fee Management</h3>
                    <p className='mb-0'>
                      Supports Multiple fee cycles that can be configured as per your school need, New fees can be introduces quickly through simply configuring it.
                    </p>
                  </div>
                  <Link
                    to={billOrbitUrl}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-danger-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-user-friends icon-sm text-danger'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Class/Grade Management</h3>
                    <p className='mb-0'>
                    Easy to Manage , Upgrade The Student Grade,Transport Routes etc.
                    </p>
                  </div>
                  <Link
                    to={billOrbitUrl}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-spell-check icon-sm text-dark'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Student Management</h3>
                    <p className='mb-0'>
                      Credibly Manages your Sudent with a inbuilt MDM features.
                    </p>
                  </div>
                  <Link
                    to={billOrbitUrl}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-cog icon-sm text-warning'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Automated Reports</h3>
                    <p className='mb-0'>
                      Generate Reports which make you take decision ahead of time to improve you incoming pending fees.
                    </p>
                  </div>
                  <Link
                    to={billOrbitUrl}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <div className='section-heading text-center'>
                
              </div>
            </div>
          </div>
          <p>
            
          </p>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-fingerprint text-warning me-2'></i> 10
                  </h3>
                  <p className='text-white'>Avg. Client Onboarding per Month</p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-paper-plane text-warning me-2'></i> 5X
                  </h3>
                  <p className='text-white'>Better Experience & Flexible</p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mt-4 mt-lg-0'>
              <div
                className='bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom'
              >
                <div className='promo-card-info mb-4'>
                  <h3 className='display-5 fw-bold mb-4 text-white'>
                    <i className='far fa-chart-pie-alt text-warning me-2'></i>
                    98%
                  </h3>
                  <p className='text-white'>Client Rated us 4+ stars on 5</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default ProductSection;
