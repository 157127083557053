import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const apiUrl = process.env.REACT_APP_LEADS_API_END_POINT

const ProductInquiryForm = () => {
  const submitForm = (_e) => {
    const responseBody = {}
    _e.preventDefault()
    const form = _e.currentTarget
    const formData = new FormData(form)
    formData.forEach((value, property) => (responseBody[property] = value))
    // TODO change the type here
    responseBody.request_type = 'contact'

    fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(responseBody),
      mode: 'no-cors',
    })
      .then((response) => {
        if (response.status === 200 || response.status === 0) {
          toast.success('Request Successful', {
            hideProgressBar: true,
            autoClose: 2000,
          })
          form.reset()
        } else {
          toast.error('Something Went Wrong! Please try again', {
            hideProgressBar: true,
            autoClose: 2000,
          })
        }
      })
      .catch(() => {
        toast.error('Something Went Wrong! Please try again', {
          hideProgressBar: true,
          autoClose: 2000,
        })
      })
  }
  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='form-notification'>
          <ToastContainer theme='colored' />
        </div>

        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>

          <form onSubmit={submitForm} className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <label htmlFor='name' className='mb-1'>
                  Name <span className='text-danger'>*</span>
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    name='name'
                    required
                    placeholder='Name'
                    aria-label='Name'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <label htmlFor='phone' className='mb-1'>
                  Phone <span className='text-danger'>*</span>
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='phone'
                    name='phone'
                    pattern='^[0-9]{10}$'
                    title='Please enter a valid phone number!'
                    required
                    placeholder='Phone'
                    aria-label='Phone'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <label htmlFor='email' className='mb-1'>
                  Email
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    name='email'
                    placeholder='Email'
                    aria-label='Email'
                  />
                </div>
              </div>
              <div className='col-sm-6 '>
                <label htmlFor='name_business_entity' className='mb-1'>
                  Business Entity Name
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    name='name_business_entity'
                    className='form-control'
                    id='name_business_entity'
                    placeholder='Business Entity Name'
                    aria-label='Business Entity Name'
                  />
                </div>
              </div>
              <div className='col-12'>
                <label htmlFor='message' className='mb-1'>
                  Message <span className='text-danger'>*</span>
                </label>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    id='message'
                    name='message'
                    required
                    placeholder='How can we help you?'
                    style={{ height: '120px' }}
                  ></textarea>
                </div>
              </div>
            </div>
            <button type='submit' className='btn btn-primary mt-4'>
              Get Started
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ProductInquiryForm
