import React from 'react'

export const CancellationAndRefundSection = () => {
  return (
    <>
      <section className='blog-details ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>

                <p>
                We value your business and strive to provide the best SaaS services to meet your needs. We understand that circumstances may change, and you may need to cancel your subscription. Please review our Cancellation & Refund Policy below:
               </p>
                <div className='blog-details-info mt-5'>
                <h3 className='h5'>Subscription Plans:</h3>
<ul className='content-list list-unstyled'>
<li>Our SaaS software operates on a subscription-based model with [monthly/yearly] billing cycles. You will not be charged upfront when you start your subscription.
</li>
</ul>

<h3 className='h5'>Cancellation:</h3>
<ul className='content-list list-unstyled'>
<li>You can cancel your subscription at any time during your subscription period without incurring any cancellation fees or charges.
</li>
<li>To cancel your subscription, simply [provide instructions on how to cancel, e.g., "log in to your account and go to the billing settings"].
</li>
</ul>

<h3 className='h5'>Refunds:</h3>
<ul className='content-list list-unstyled'>
<li>As we do not charge upfront for our services, there is no refund for unused subscription time if you decide to cancel during your billing cycle.
</li>
<li>You will not be billed for the subsequent billing cycle following your cancellation.</li>
</ul>



<h3 className='h5'>No Partial Refunds:</h3>
<ul className='content-list list-unstyled'>
<li>We do not offer partial refunds for unused subscription time within a billing cycle.</li>
</ul>


<h3 className='h5'>Contact Us:</h3>
<ul className='content-list list-unstyled'>
<li>If you have any questions about our Cancellation & Refund Policy, or if you encounter any issues with your subscription or billing, please contact our support team at [support email or phone number].
</li>
</ul>

<h3 className='h5'>Changes to Policy:</h3>
<ul className='content-list list-unstyled'>
<li>We reserve the right to update or modify this Cancellation & Refund Policy at any time. Any changes will be effective upon posting the revised policy on our website. We will make reasonable efforts to inform you of significant changes.
</li>
<li>By using our services, you agree to abide by this Cancellation & Refund Policy. We recommend that you review this policy periodically to stay informed of any updates.
</li>
</ul>
                  <blockquote className='bg-white custom-shadow p-5 mt-5 rounded-custom border-4 border-primary border-top'>
                    <p className='text-muted'>
                      <i className='fas fa-quote-left me-2 text-primary'></i>{' '}
                      We Do not Charge Any Up-Front Fee and You can Cancel Anytime before The Term End without incurring Any Charges<i className='fas fa-quote-right ms-2 text-primary'></i>
                    </p>
                  </blockquote>
                </div>

              </div>
            </div>
            <div className='col-lg-4'>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};