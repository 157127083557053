import React from 'react'
import SectionTitle from './common/SectionTitle';

export const ServiceSection = () => {
  return (
    <section id='servicesSection' className='services-icon ptb-120'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-10'>
            <SectionTitle
                  subtitle='Services'
                  title='What We Do?'
                  description='We build value for our customers'
                  centerAlign
            />
          </div>
        </div>
        
        <div className='row justify-content-center'>
          <div className='col-md-6 p-0'>
            <div className='single-service p-lg-5 p-4 text-center border-top border-start border-bottom border-end'>
              <div className='service-icon icon-center'>
                <img
                  src='assets/images/coding.png'
                  alt='service icon'
                  width='65'
                  height='65'
                />
              </div>
              <div className='service-info-wrap'>
                <h3 className='h5'>Digital Strategy</h3>
                <p>
                  Client-focused, Customer-centric, Creating strategic solutions
                  based in technology, GO Orbit helps enterprises and brands
                  navigate all facets of the digital landscape to deliver
                  tangible business results.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 p-0'>
            <div className='single-service p-lg-5 p-4 text-center border-bottom border-top border-end'>
              <div className='service-icon icon-center'>
                <img
                  src='assets/images/app-development.png'
                  alt='service icon'
                  width='65'
                  height='65'
                />
              </div>
              <div className='service-info-wrap'>
                <h3 className='h5'>Product Development</h3>
                <p>
                  Scalable product development that employs key talent resources
                  and the best practices in software development delivery to
                  take your product from idea to deployment and user adoption
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 p-0'>
            <div className='single-service p-lg-5 p-4 text-center border-start border-bottom border-end'>
              <div className='service-icon icon-center'>
                <img
                  src='assets/images/graphic-design.png'
                  alt='service icon'
                  width='65'
                  height='65'
                />
              </div>
              <div className='service-info-wrap'>
                <h3 className='h5'>Data Engineering</h3>
                <p>
                  Increase your competitive strength and growth through a
                  modernised data pipeline solution. GO Orbit offers fast and
                  accurate data analytics for better informed decisions for
                  businesses with our team of data experts.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 p-0'>
            <div className='single-service p-lg-5 p-4 text-center border-bottom border-end'>
              <div className='service-icon icon-center'>
                <img
                  src='assets/images/curve.png'
                  alt='service icon'
                  width='65'
                  height='65'
                />
              </div>
              <div className='feature-info-wrap'>
                <h3 className='h5'>UI/UX Solution</h3>
                <p>
                  With our complete line of UI/UX services, we'll help you
                  design such a UX strategy that best fits your business goals
                  to help you grow your business and take it to the new heights.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
