import React from 'react'
import { Link } from 'react-router-dom'

export const OffCanvasMenu = ({ billOrbitUrl }) => {
  const smoothScroll = (ele) => {
    ele.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const scrollToHome = () => {
    let home = document.getElementById('home')
    smoothScroll(home)
  }

  const scrollToContactUsSection = () => {
    let home = document.getElementById('contactUsSection')
    smoothScroll(home)
  }

  const scrollToAboutUs = () => {
    let features = document.getElementById('aboutUsSection')
    smoothScroll(features)
  }
  const scrollToServices = () => {
    let pricing = document.getElementById('servicesSection')
    smoothScroll(pricing)
  }

  const scrollToCareersSection = () => {
    let testimonials = document.getElementById('careerSection')
    smoothScroll(testimonials)
  }

  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasWithBackdrop'
      >
        <div className='offcanvas-header d-flex align-items-center mt-4'>
          <Link
            to='/'
            className='d-flex align-items-center mb-md-0 text-decoration-none bill-orbit-logo'
          >
            <img
              src='assets/images/logo.png'
              alt='logo'
              className='img-fluid ps-2'
            />
          </Link>
          <button
            type='button'
            className='close-btn text-danger'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          >
            <i className='far fa-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
            <li>
              <Link to='/' className='nav-link' onClick={scrollToHome}>
                Home
              </Link>
            </li>
            <li>
              <Link to='/' className='nav-link' onClick={scrollToAboutUs}>
                About Us
              </Link>
            </li>
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle'
                to='/'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Products
              </Link>
              <div className='dropdown-menu border-0 rounded-custom shadow py-0 bg-white'>
                <div className='dropdown-grid rounded-custom width-full'>
                  <div className='dropdown-grid-item'>
                    <div className='dropdown-link'>
                      <div className='dropdown-info'>
                        <div className='drop-title'>
                          <a href={billOrbitUrl}>Bill Orbit</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to='/' className='nav-link' onClick={scrollToServices}>
                Services
              </Link>
            </li>
            <li>
              <Link
                to='/'
                onClick={scrollToContactUsSection}
                className='nav-link'
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                to='/'
                className='nav-link'
                onClick={scrollToCareersSection}
              >
                Career
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
