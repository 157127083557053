import React from 'react'
import { Layout } from './components/Layout'
import { HeaderSection } from './components/HeaderSection'
import { IntroSection } from './components/IntroSection/IntroSection'
import { ProductInquirySection } from './components/ProductInquirySection'
import { CareerSection } from './components/CareerSection'
import { FooterSection } from './components/FooterSection'
import { AboutUsSection } from './components/AboutUsSection'
import { ServiceSection } from './components/ServiceSection'
import ProductSection from './components/ProductSection'

const MainContainer = () => {
  return (
    <Layout>
      <HeaderSection classOption='navbar-light' />
      <IntroSection />
      <AboutUsSection />
      <ProductSection />
      <ServiceSection />
      <ProductInquirySection />
      <CareerSection />
      <FooterSection
        style={{
          background:
            "url('assets/images/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  )
}

export default MainContainer
